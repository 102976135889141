import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Contact from "../components/contact-slice"

import ReactLogo from "../images/logos/react.png"
import PHPLogo from "../images/logos/php.png"
import NodeLogo from "../images/logos/node.png"
import MysqlLogo from "../images/logos/mysql.png"
import PostreLogo from "../images/logos/PostgreSQL.png"
import ApacheLogo from "../images/logos/apache.png"
import ReduxLogo from "../images/logos/redux.png"

import SafetumLogo from "../images/logos/Safetum_Logo_Midnight_RGB.png"
import KemiLogo from "../images/logos/kemi-shipping.png"
import KauppuriLogo from "../images/logos/kauppuri.png"
import VocatumLogo from "../images/logos/vocatum.png"
import OuluLogo from "../images/logos/oulu-sivistys.png"

import EasyToUse from "../images/easy-to-use.jpg"
import AvailableEverywhere from "../images/available-everywhere.jpg"
import NeedsPicture from "../images/needs.jpg"
import VersionPicture from "../images/version.jpg"
import BottomPicture from "../images/svg/static_assets.svg"
import ScrollAnim from "react-animate-on-scroll"
import Picture from "../images/blog-post.jpg"

export default () => (
  <Layout>
    <SEO
      title={"Web-sovellukset"}
      description={
        "Liiketoiminnan sähköistäminen -investointi joka kannattaa! Päästä paperilaput ja excel-tiedostot eläkkeelle ja siirry nykyaikaan."
      }
      image={Picture}
    />
    <Header siteTitle={"Web-sovellukset"} />
    <section className="slice slice-lg pb-0">
      <div className={"container d-flex justify-content-center text-center"}>
        <div className={"col-md-8"}>
          <h2>Liiketoiminnan sähköistäminen -investointi joka kannattaa!</h2>
          <p className={"lead"}>
            Toteutamme alusta loppuun mm. tarkastustyökalut, tilastointijärjestelmät,
            liiketoimintaasi räätälöidyt toiminnanohjausjärjestelmät, extranetit, intranetit, erilaiset
            sähköiset työkalut esimerkiksi kartoitustyökalut myyjien tai
            tarkastajien työtä tukemaan. Hoidamme myös rakentamiemme
            verkkopalveluiden ylläpidon.
          </p>
        </div>
      </div>
      <div className="container mt-6">
        <div
          className={
            "row d-flex justify-content-center align-items-center text-center"
          }
        >
          <div className="client col-6 col-sm-4 col-md-3 mb-4">
            <img src={MysqlLogo} className={"w-100"} alt={"Mysql logo"} />
          </div>
          <div className="client col-6 col-sm-4 col-md-3 mb-4">
            <img src={ReactLogo} className={"w-100"} alt={"React Logo"} />
          </div>
          <div className="client col-6 col-sm-4 col-md-3 mb-4">
            <img src={PHPLogo} className={"w-100"} alt={"PHP Logo"} />
          </div>
          <div className="client col-6 col-sm-4 col-md-3 mb-4">
            <img src={PostreLogo} className={"w-100"} alt={"PostreSQL Logo"} />
          </div>
          <div className="client col-6 col-sm-4 col-md-3 mb-4 mb-md-0">
            <img src={ApacheLogo} className={"w-100"} alt={"Apache logo"} />
          </div>
          <div className="client col-6 col-sm-4 col-md-3 mb-4 mb-md-0">
            <img src={NodeLogo} className={"w-100"} alt={"Nodejs logo"} />
          </div>
          <div className="client col-6 col-sm-4 col-md-3 mb-4 mb-md-0">
            <img src={ReduxLogo} className={"w-100"} alt={"Redux logo"} />
          </div>
        </div>
      </div>
    </section>
    <section className="section-process slice-lg pb-0 pt-0 pt-md-5">
      <div className="section-process-step">
        <div className="container">
          <div className="row row-grid align-items-center justify-content-between">
            <div className="col-xl-5 col-lg-6 order-lg-2">
              <div className="pr-md-4">
                <h3 className="mt-0 mt-lg-4">Tarpeesi ja toiveittesi mukaan</h3>
                <p className="lead my-4">
                  Kaikki ohjelmointityömme tehdään asiakkaiden todellisten
                  tarpeiden tyydyttämiseksi. Osallistumme mielellämme aktiivisesti
                  suunnitteluprosessiin tai toteuttaa valmiiksi suunnitellut
                  järjestelmät yhteistyössä asiakkaan kanssa. Toteutettavan järjestelmän ei tarvitse aina olla mammuttimainen kokonaisuus, kysy meiltä, 
                  miten voisimme tehostaa liiketoimintaanne ketterästi!
                </p>
              </div>
            </div>
            <div className="col-lg-5 order-lg-1">
              <ScrollAnim
                animateIn="fadeIn"
                duration="0.5"
                animateOnce="true"
                offset="120"
              >
                <img
                  alt="Tarpeesi ja toiveittesi mukaan"
                  src={NeedsPicture}
                  className="img-center img-fluid rounded-lg shadow-lg"
                />
              </ScrollAnim>
            </div>
          </div>
        </div>
      </div>
      <div className="section-process-step">
        <div className="container">
          <div className="row row-grid align-items-center justify-content-between">
            <div className="col-xl-5 col-lg-6">
              <div className="pr-md-4">
                <h3 className="mt-0 mt-lg-4">
                  Aina saatavilla, missä vain, miten vain
                </h3>
                <p className="lead my-4">
                  Rakentamamme web-sovellukset toimivat myös yrityksesi
                  ulkopuolella – kunhan sinulla on pääsy internetiin. Palvelumme
                  toimivat moderneilla verkkopäätteillä, kuten älypuhelimilla,
                  tableteilla ja tietysti työpöytälaitteilla. Näin voit tehdä
                  töitä myös tehokkaasti ”kentällä” ja säästää reilusti aikaa
                  konttorilla tehtävältä työltä. Kysy myös mobiilioptimoituja PWA-toteutuksia!
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <ScrollAnim
                animateIn="fadeIn"
                duration="0.5"
                animateOnce="true"
                offset="120"
              >
                <img
                  alt="Aina saatavilla, missä vain, miten vain"
                  src={AvailableEverywhere}
                  className="img-center img-fluid rounded-lg shadow-lg"
                />
              </ScrollAnim>
            </div>
          </div>
        </div>
      </div>
      <div className="section-process-step">
        <div className="container">
          <div className="row row-grid align-items-center justify-content-between">
            <div className="col-xl-5 col-lg-6 order-lg-2">
              <div className="pr-md-4">
                <h3 className="mt-0 mt-lg-4">Aina uusin versio</h3>
                <p className="lead my-4">
                  Web-sovelluksissa käytössäsi on aina järjestelmän uusin versio. Sovelluksien päivityksestä ei tarvitse huolehtia.
                  Hoidamme sovitusti myös järjestelmien ylläpidon ja
                  jatkokehityksen, jotta voitte keskittyä ydinosaamiseenne.
                </p>
              </div>
            </div>
            <div className="col-lg-5 order-lg-1">
              <ScrollAnim
                animateIn="fadeIn"
                duration="0.5"
                animateOnce="true"
                offset="120"
              >
                <img
                  alt="Aina uusin versio"
                  src={VersionPicture}
                  className="img-center img-fluid shadow-lg rounded-lg"
                />
              </ScrollAnim>
            </div>
          </div>
        </div>
      </div>
      <div className="section-process-step">
        <div className="container">
          <div className="row row-grid align-items-center justify-content-between">
            <div className="col-xl-5 col-lg-6">
              <div className="pr-md-4">
                <h3 className="mt-0 mt-lg-4">
                  Helppo käyttää, jopa ilman ohjeita
                </h3>
                <p className="lead my-4">
                  Rakennamme web-sovelluksemme mahdollisimman helppokäyttöisiksi
                  kaikille käyttäjille. Aikaa ei kulu ohjeita selatessa. 
                  Tarvittaessa perehdytämme henkilöstösi palveluiden käyttöön.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <ScrollAnim
                animateIn="fadeIn"
                duration="0.5"
                animateOnce="true"
                offset="120"
              >
                <img
                  alt="Helppo käyttää, jopa ilman ohjeita"
                  src={EasyToUse}
                  className="img-center img-fluid shadow-lg rounded-lg"
                />
              </ScrollAnim>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={"container pb-6"}>
      <div className="row justify-content-center align-items-center mt-5 pb-4 text-center">
        <div className="client col-4 py-3">
          <img alt="Safetum logo" src={SafetumLogo} />
        </div>
        <div className="client col-4 py-3">
          <img alt="Kemishipping logo" src={KemiLogo} />
        </div>
        <div className="client col-4 py-3">
          <img alt="Kauppuri logo" src={KauppuriLogo} />
        </div>
        <div className="client col-4 py-3">
          <img alt="Oulu sivistys ja kulttuuripalvelut logo" src={OuluLogo} />
        </div>
        <div className="client col-4 py-3">
          <img alt="Vocatum logo" src={VocatumLogo} />
        </div>
      </div>
    </section>
    <div
      className={
        "container slice-lg pt-0 justify-content-center d-none d-md-flex"
      }
    >
      <ScrollAnim
        animateIn="fadeIn"
        duration="0.5"
        animateOnce="true"
        offset="100"
        className={"d-none d-md-flex justify-content-center"}
      >
        <img src={BottomPicture} className={"w-50"} alt={"Footer"} />
      </ScrollAnim>
    </div>
    <Contact />
  </Layout>
)
